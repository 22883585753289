import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';

import {MainPage} from "./Pages/MainPage";

export default class App extends Component {
    render() {
        return (
            <Router>
                <Routes>
                    <Route exact path="/" element={<MainPage/>}/>
                    <Route path="/signin" element={<MainPage/>}/>
                    {/*<Route exact path="/" component={MainPage}/>*/}
                    {/*<Route path="/signin" component={MainPage}/>*/}
                    {/*<Route*/}
                    {/*    path="/templatefilling/:id"*/}
                    {/*    render={props => (*/}
                    {/*        <Layout*/}
                    {/*            {...props}*/}
                    {/*            component={TemplateFillingPage}*/}
                    {/*            title="ROM Review Outputs"*/}
                    {/*        />*/}
                    {/*    )}*/}
                    {/*/>*/}
                </Routes>
            </Router>
        );
    }
}


