import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Web3Contract from "web3-eth-contract";
import Web3 from "web3";

// import Web3 from "web3";
// import Web3Contract from "web3-eth-contract";


// var Web3 = require("web3");
// const web3 = new Web3("http://127.0.0.1:8551")

// async function getBlockNumber() {
//     const latestBlockNumber = await web3.eth.getBlockNumber()
//     console.log(latestBlockNumber)
//     return latestBlockNumber
// }

let contract_abi = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "a_Administrator",
                "type": "address"
            }
        ],
        "name": "addAdministrator",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [
            {
                "internalType": "bool",
                "name": "success",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_amount",
                "type": "uint256"
            }
        ],
        "name": "burn",
        "outputs": [
            {
                "internalType": "bool",
                "name": "success",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Approve",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "ui_match_id",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "ui_team_id",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "ui_amount",
                "type": "uint256"
            }
        ],
        "name": "matchStake",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "matchUnStake",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_amount",
                "type": "uint256"
            }
        ],
        "name": "mint",
        "outputs": [
            {
                "internalType": "bool",
                "name": "success",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "a_Administrator",
                "type": "address"
            }
        ],
        "name": "removeAdministrator",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "ui_m1",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "ui_m2",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "ui_m3",
                "type": "uint256"
            }
        ],
        "name": "setMatchStakeFreezes",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "a_owner",
                "type": "address"
            }
        ],
        "name": "setOwner",
        "outputs": [
            {
                "internalType": "bool",
                "name": "success",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "ui_r1",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "ui_r2",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "ui_r3",
                "type": "uint256"
            }
        ],
        "name": "setWinnerRewardFreezes",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "transfer",
        "outputs": [
            {
                "internalType": "bool",
                "name": "success",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "_to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [
            {
                "internalType": "bool",
                "name": "success",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "a_winner",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "ui_amount",
                "type": "uint256"
            }
        ],
        "name": "winnerRewardsStakeWithDetails",
        "outputs": [
            {
                "internalType": "bool",
                "name": "success",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "winnerRewardUnstake",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "administrators",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "allowance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "decimals",
        "outputs": [
            {
                "internalType": "uint8",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getAdministrators",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getAvailableNonStakedBalance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "balance",
                "type": "uint256"
            }
        ],
        "stateMutability": "pure",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "a_stake",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "ui_match_id",
                "type": "uint256"
            }
        ],
        "name": "getMatchStake",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "p_a",
                "type": "address"
            }
        ],
        "name": "getMatchStakes",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getMsgSender",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getOwner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getStakedBalance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "balance",
                "type": "uint256"
            }
        ],
        "stateMutability": "pure",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getTotalBalance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "balance",
                "type": "uint256"
            }
        ],
        "stateMutability": "pure",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "p_a",
                "type": "address"
            }
        ],
        "name": "getWinnerStakes",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "matchStakeFreeze1",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "matchStakeFreeze2",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "matchStakeFreeze3",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address payable",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "stakes_games",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "freeze_due_date",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "match_id",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "team_id",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "stakes_rewards",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "freeze_due_date",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "winnerRewardFreeze1",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "winnerRewardFreeze2",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "winnerRewardFreeze3",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
];

let URL = "http://127.0.0.1:8545";  // Ganache
// let URL = "https://data-seed-prebsc-1-s1.binance.org:8545"; // BSC Testnet
// let URL = "https://ropsten.infura.io/v3/ef30f13503494a0b944450de6846ea26"; // Infura

let contract_address = "0x917E6aca980813985f6B7D4c783aeAB84Ce37797"; // Ganache
// let contract_address = "0x2594275fD1206DA383A48f5F178C3Cfea2Ca0008"; // BSC Testnet

export class MainPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wallet_address: "",
            stake_match_id: "2022150001",
            stake_team_id: 1,
            stake_amount: 3000000000000000000,
            winner_address: "",
            winner_amount: 3,
            wallet_ask_stake: "",
        };

        // localStorage.removeItem("authtoken");
    }

    componentDidMount = async () => {
        // const URL = "http://127.0.0.1:8545";
        var Web3Contract = require('web3-eth-contract');
        Web3Contract.setProvider(URL);
        var t_contract = new Web3Contract(contract_abi, contract_address);

        let t_owner_result = await t_contract.methods.getOwner().call();
        console.log("Owner: " + t_owner_result);
        this.setState({
            wallet_address: t_owner_result
        });
    }

    handleChange = (e) => {
        // console.log("handleChange " + e.target.name + " " + e.target.value);
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    // runTest = async () => {
    //     await this.test();
    // }

    async test() {
        const Web3 = require('web3');
        // const URL = "http://127.0.0.1:8551";
        // const URL = "http://127.0.0.1:7545";
        // const URL = "http://127.0.0.1:8545";
        // const URL = "https://cloudflare-eth.com";
        // const URL = "https://mainnet.infura.io/v3/ca689c96846043b18d038f8c97174cf6";
        const web3 = new Web3(new Web3.providers.HttpProvider(URL));

        web3.eth.getBlockNumber(function (error, result) {
            console.log("Block Number: " + result)
        })

        // const address = "0x507e09F681f5062D1e42a72fc84019B83CDf6bCD";
        const address = "";
        web3.eth.getBalance(address, (err, wei) => {
            let balance = web3.fromWei(wei, 'ether')
            console.log("Balance: " + balance);
        });

        const web3_1 = new Web3(window.ethereum);
        await window.ethereum.enable();

        var Web3Contract = require('web3-eth-contract');
        Web3Contract.setProvider(URL);
        var t_contract = new Web3Contract(contract_abi, contract_address);
        let t_owner_result = await t_contract.methods.getOwner().call();
        console.log("Owner: " + t_owner_result);
        let t_admin_result = await t_contract.methods.getAdministrator().call();
        console.log("Admin: " + t_admin_result);

        await t_contract.methods.setOwner(this.state.wallet_address)
            // .send({from: "0x507e09F681f5062D1e42a72fc84019B83CDf6bCD"});
            .send({from: window.ethereum.selectedAddress});

    }

    setOwnerWalletAddress = async () => {
        console.log("setOwnerWalletAddress");

        const Web3 = require('web3');
        // const web3 = new Web3(new Web3.providers.HttpProvider(URL));
        const web3 = new Web3(window.ethereum)
        await window.ethereum.enable();

        // const URL = "http://127.0.0.1:8545";
        var Web3Contract = require('web3-eth-contract');
        Web3Contract.setProvider(URL);
        var t_contract = new Web3Contract(contract_abi, contract_address);
        await t_contract.methods.setOwner(this.state.wallet_address)
            .send({from: window.ethereum.selectedAddress});

        let t_owner_result = await t_contract.methods.getOwner().call();
        console.log("Owner: " + t_owner_result);
        this.setState({
            wallet_address: t_owner_result
        });
    }

    doMatchStake = async () => {
        console.log("doMatchStake");

        const Web3 = require('web3');
        // const web3 = new Web3(new Web3.providers.HttpProvider(URL));
        const web3 = new Web3(window.ethereum)
        await window.ethereum.enable();

        // const URL = "http://127.0.0.1:8545";
        var Web3Contract = require('web3-eth-contract');
        Web3Contract.setProvider(URL);
        var t_contract = new Web3Contract(contract_abi, contract_address);
        await t_contract.methods.matchStake(this.state.stake_match_id, this.state.stake_team_id, this.state.stake_amount)
            .send({
                from: window.ethereum.selectedAddress, gas: 1500000, gasPrice: '30000000000000'
            }, (err, res) => {
                console.log("doMatchStake send res: " + res);
            });
    }

    getMatchStakes = async () => {
        console.log("getMatchStakes");

        const Web3 = require('web3');
        // const web3 = new Web3(new Web3.providers.HttpProvider(URL));
        const web3 = new Web3(window.ethereum)
        await window.ethereum.enable();
        console.log("account: " + window.ethereum.selectedAddress);
        // web3.eth.requestAccounts().then(console.log);

        // var web3c = new web3.eth.contract(contract_abi);
        // var v_t = await web3c.at(contract_address, function f() {
        //     console.log("function f");
        // }).methods.getMatchStakes().call();
        // console.log("Stakes1: " + v_t);

        var Web3Contract = require('web3-eth-contract');
        Web3Contract.setProvider(URL);
        var t_contract = new Web3Contract(contract_abi, contract_address);

        let t_stakes_result = await t_contract.methods.getMatchStakes(this.state.wallet_ask_stake).call();
        console.log("Match Stakes: " + t_stakes_result);
    }

    getWinnerStakes = async () => {
        console.log("getWinnerStakes");

        const Web3 = require('web3');
        // const web3 = new Web3(new Web3.providers.HttpProvider(URL));
        const web3 = new Web3(window.ethereum)
        await window.ethereum.enable();
        console.log("account: " + window.ethereum.selectedAddress);

        var Web3Contract = require('web3-eth-contract');
        Web3Contract.setProvider(URL);
        var t_contract = new Web3Contract(contract_abi, contract_address);

        let t_stakes_result = await t_contract.methods.getWinnerStakes(this.state.wallet_ask_stake).call();
        console.log("Winner Reward Stakes: " + t_stakes_result);
    }


    getMsgSender = async () => {
        console.log("getMsgSender");

        const Web3 = require('web3');
        // const web3 = new Web3(new Web3.providers.HttpProvider(URL));
        const web3 = new Web3(window.ethereum)
        await window.ethereum.enable();
        console.log("account: " + window.ethereum.selectedAddress);

        var Web3Contract = require('web3-eth-contract');
        Web3Contract.setProvider(window.ethereum);

        var t_contract = new Web3Contract(contract_abi, contract_address);
        t_contract.defaultAccount = window.ethereum.selectedAddress;

        let t_msg_sender = await t_contract.methods.getMsgSender().call();
        console.log("Msg Sender: " + t_msg_sender);
    }

    doMatchWinning = async () => {
        console.log("doMatchWinning");

        const Web3 = require('web3');
        // const web3 = new Web3(new Web3.providers.HttpProvider(URL));
        const web3 = new Web3(window.ethereum)
        await window.ethereum.enable();

        var Web3Contract = require('web3-eth-contract');
        Web3Contract.setProvider(URL);
        var t_contract = new Web3Contract(contract_abi, contract_address);
        await t_contract.methods.winnerRewardsStakeWithDetails(this.state.winner_address, this.state.winner_amount)
            .send({
                from: window.ethereum.selectedAddress, gas: 1500000, gasPrice: '30000000000000'
            }, (err, res) => {
                console.log("doMatchWinning send res: " + res);
            });
    }

    render() {
        return (<div>
                <table width={"100%"}>
                    <thead></thead>
                    <tbody>
                    <tr>
                        {/*<td>*/}
                        {/*    <p>Running...</p>*/}
                        {/*</td>*/}
                        <td>
                            <TextField
                                label="WalletAddr: "
                                placeholder="0x000"
                                margin="normal" fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={this.state.wallet_ask_stake}
                                onChange={this.handleChange.bind(this)}
                                name="wallet_ask_stake"
                                type="text"
                                style={{width: "25%", float: "left"}}
                            />
                            <div style={{width: "25%", float: "left"}}>
                                <Button variant="contained" color="primary"
                                        onClick={this.getMatchStakes.bind(this)}>Get Match Stakes</Button>
                            </div>
                            <div style={{width: "25%", float: "left"}}>
                                <Button variant="contained" color="primary"
                                        onClick={this.getWinnerStakes.bind(this)}>Get Winner Stakes</Button>
                            </div>
                            <div style={{width: "25%", float: "left"}}>
                                <Button variant="contained" color="primary"
                                        onClick={this.getMsgSender.bind(this)}>Get Sender</Button>
                            </div>
                        </td>
                        <td></td>
                    </tr>

                    <tr>
                        {/*<td></td>*/}
                        <td>
                            <TextField
                                label="Wallet Address: "
                                placeholder="0x000"
                                margin="normal" fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={this.state.wallet_address}
                                onChange={this.handleChange.bind(this)}
                                name="wallet_address"
                                type="text"
                            />
                        </td>
                        <td>
                            <Button variant="contained" color="primary"
                                    onClick={this.setOwnerWalletAddress.bind(this)}>Set Owner</Button>
                        </td>
                    </tr>
                    <tr>
                        {/*<td></td>*/}
                        <td>
                            <div style={{width: "25%", float: "left"}}>
                                <TextField
                                    label="Match ID: "
                                    placeholder=""
                                    margin="normal" fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.stake_match_id}
                                    onChange={this.handleChange.bind(this)}
                                    name="stake_match_id"
                                    type="text"
                                />
                            </div>
                            <div style={{width: "25%", float: "left"}}>
                                <TextField
                                    label="Team ID: "
                                    placeholder=""
                                    margin="normal" fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.stake_team_id}
                                    onChange={this.handleChange.bind(this)}
                                    name="stake_team_id"
                                    type="text"
                                />
                            </div>
                            <div style={{width: "25%", float: "left"}}>
                                <TextField
                                    label="Stake Amount: "
                                    placeholder=""
                                    margin="normal" fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.stake_amount}
                                    onChange={this.handleChange.bind(this)}
                                    name="stake_amount"
                                    type="text"
                                />
                            </div>
                        </td>
                        <td>
                            <Button variant="contained" color="primary"
                                    onClick={this.doMatchStake.bind(this)}>Match Stake</Button>
                        </td>
                    </tr>

                    <tr>
                        {/*<td></td>*/}
                        <td>
                            <div style={{width: "25%", float: "left"}}>
                                <TextField
                                    label="Winner Wallet Address: "
                                    placeholder="0x000"
                                    margin="normal" fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.winner_address}
                                    onChange={this.handleChange.bind(this)}
                                    name="winner_address"
                                    type="text"
                                />
                            </div>
                            <div style={{width: "25%", float: "left"}}>
                                <TextField
                                    label="Amount: "
                                    placeholder=""
                                    margin="normal" fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.winner_amount}
                                    onChange={this.handleChange.bind(this)}
                                    name="stake_team_id"
                                    type="text"
                                />
                            </div>
                        </td>
                        <td>
                            <Button variant="contained" color="primary"
                                    onClick={this.doMatchWinning.bind(this)}>Match Win</Button>
                        </td>
                    </tr>

                    </tbody>
                    <tfoot></tfoot>
                </table>
            </div>);
    }
}


// handleChange = (e) =>


//
// }
//
// componentDidMount() {
//
//     // this.setState({
//     //     passwordresetrandom: this.props.match.params.randomtoken
//     // });
// }
//
// function resetPassword()
// {
//
// }

// let state = {
//     password1: ""
// }
//
//
// function handleChange(e) {
//     console.log("handleChange " + e.target.name + " " + e.target.value);
//     state.password1 = e.target.value;
// }
//
// function resetPassword() {
//     console.log("resetPassword");
// }


// async function func1() {
//     const Web3 = require('web3');
//     const URL = "https://mainnet.infura.io/v3/ca689c96846043b18d038f8c97174cf6";
//     const web3 = new Web3(new Web3.providers.HttpProvider(URL));
//     console.log(web3.version.api);
//
//     const address = "0xa92b75a1349426380edd57b4858674cdb327a30d";
//     let balance;
//     web3.eth.getBalance(address, (err, wei) => {
//         balance = web3.fromWei(wei, 'ether')
//     });
//     // console.log(balance.toString());
//     console.log(balance);
//     // console.log(await web3.eth.getBlockNumber());
//
//     web3.eth.getBlockNumber(function (error, result) {
//         console.log(result)
//     })
// }

// function App() {
//
//     test();
//     // web3.eth.getBlockNumber();
//     // func1();
//
//     return (<div className="App">
//         <p>
//             Running...
//         </p>
//         <TextField
//             label="Enter Your Password:"
//             placeholder="Password"
//             margin="normal" fullWidth
//             InputLabelProps={{
//                 shrink: true,
//             }}
//             value={state.password1}
//             onChange={handleChange}
//             name="passwordbox"
//             type="password"
//         />
//         <Button variant="contained" color="primary" onClick={resetPassword}> Reset </Button>
//     </div>);
// }
//
// export default App;
